import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button, Flex, Slider } from 'antd';

import API from 'services/API';

import Cropper, { Area, Point } from '../EasyCrop';

type CropModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  crop: { x: number; y: number };
  zoom: number;
  aspectRatio: number;
  sessionId: string;
  sideId: string;
  onCropChange: (location: Point) => void;
  onZoomChange: (zoom: number) => void;
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
};

const CropModal = ({
  isOpen,
  onClose,
  onSave,
  sessionId,
  sideId,
  crop,
  aspectRatio,
  zoom,
  onCropChange,
  onZoomChange,
  onCropComplete,
}: CropModalProps) => {
  const { t } = useTranslation();

  const { data, isError, isLoading, isFetching } = useQuery('cropImage', () =>
    API.postGeneratePreviewForCrop(sessionId, sideId),
  );

  const isLoadingOrError = isLoading || isError || isFetching;

  // Function to calculate new crop position to keep the image steady during zoom
  const handleWheelZoom = (e: WheelEvent) => {
    e.preventDefault();

    const deltaZoom = e.deltaY > 0 ? -0.01 : 0.01; // Zoom out if deltaY > 0, else zoom in (small step)
    let newZoom = zoom + deltaZoom;

    // Ensure zoom stays within min and max bounds
    newZoom = Math.min(Math.max(newZoom, 1), 5);

    // Calculate mouse position relative to the image container
    const imageContainer = document.querySelector('.crop-container') as HTMLElement;
    const rect = imageContainer.getBoundingClientRect();
    const mouseX = (e.clientX - rect.left) / rect.width;
    const mouseY = (e.clientY - rect.top) / rect.height;

    // Adjust crop position to keep image steady at zoom point
    const newCropX = crop.x - mouseX * (newZoom - zoom);
    const newCropY = crop.y - mouseY * (newZoom - zoom);

    onCropChange({ x: newCropX, y: newCropY });
    onZoomChange(newZoom);
  };

  useEffect(() => {
    // Add event listener for wheel events to handle zooming
    window.addEventListener('wheel', handleWheelZoom);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('wheel', handleWheelZoom);
    };
  }, [zoom, crop]);

  return (
    <div
      style={{
        display: isOpen ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1000,
        left: '0',
        top: '0',
        width: '100%',
        height: '100%',
        overflow: 'auto', // Allows scrolling if the content is too long
        backgroundColor: 'gray',
      }}
      aria-label="Crop Modal"
    >
      {isLoadingOrError ? (
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          {isLoading && <h2>{t('CropModal.Loading')}</h2>}
          {isError && <h2>{t('CropModal.Error')}</h2>}
        </Flex>
      ) : (
        <>
          <div
            className="crop-container"
            style={{
              width: '100%',
              height: '90%',
              position: 'relative',
              top: '5px',
            }}
          >
            <Cropper
              image={data?.previewCropImageUrl}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropComplete}
              objectFit="contain"
            />
          </div>
          <Flex justify="center" align="center" style={{ padding: '1rem' }}>
            <Slider
              value={zoom}
              onChange={onZoomChange}
              min={1}
              max={5}
              step={0.01}
              style={{ width: '50%' }}
            />
            <Button onClick={onSave} type="primary" danger style={{ marginLeft: '1rem' }}>
              {t('CropModal.Save')}
            </Button>
            <Button onClick={onClose} style={{ marginLeft: '1rem' }}>
              {t('Cancel')}
            </Button>
          </Flex>
        </>
      )}
    </div>
  );
};

export default CropModal;
